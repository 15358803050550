import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
} from "@heroicons/react/16/solid";
import {
  ChartBarIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentTextIcon,
  EnvelopeIcon,
  FingerPrintIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { Link, router } from "@inertiajs/react";
import { Avatar } from "../components/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../components/dropdown";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from "../components/sidebar";
import { SidebarLayout } from "../components/sidebar-layout";
import Application from "./application";
import logo from "/assets/lavish.svg";

export default function WithSidebar({ children, ...props }) {
  const { current_user, path } = props;
  const userInitials = current_user.name
    .split(" ")
    .map((n) => n?.[0].toUpperCase())
    .join("");

  return (
    <Application>
      <SidebarLayout
        sidebar={
          <Sidebar>
            <SidebarBody>
              <div className="mb-2 flex">
                <Link href="/" aria-label="Home">
                  <img src={logo} alt="Lavish" className="size-12 sm:size-11" />
                </Link>
              </div>

              <SidebarSection>
                <SidebarItem href="/" current={path === "/"}>
                  <ChartBarIcon />
                  <SidebarLabel>Dashboard</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href="/campaigns"
                  current={path.startsWith("/campaign")}
                >
                  <EnvelopeIcon />
                  <SidebarLabel>Campaigns</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href="/contacts"
                  current={
                    path.startsWith("/contacts") ||
                    path.startsWith("/segments") ||
                    path.startsWith("/fields") ||
                    path.startsWith("/tags")
                  }
                >
                  <UsersIcon />
                  <SidebarLabel>Contacts</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href="/senders"
                  current={path.startsWith("/senders")}
                >
                  <FingerPrintIcon />
                  <SidebarLabel>Senders</SidebarLabel>
                </SidebarItem>
              </SidebarSection>

              <SidebarSection>
                <SidebarHeading>Resources</SidebarHeading>
                <SidebarItem
                  href=""
                  onClick={() =>
                    (window.location.href = "mailto:support@lavish.so")
                  }
                >
                  <QuestionMarkCircleIcon />
                  <SidebarLabel>Support</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href=""
                  onClick={() =>
                    window.open(
                      "https://join.slack.com/t/lavishso/shared_invite/zt-2s7mxze00-_WNomluOZlK~B5GTyMI6Pw",
                      "_blank"
                    )
                  }
                >
                  <ChatBubbleBottomCenterTextIcon />
                  <SidebarLabel>Community</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href=""
                  onClick={() =>
                    window.open("https://www.lavish.so/changelog", "_blank")
                  }
                >
                  <SparklesIcon />
                  <SidebarLabel>What's new</SidebarLabel>
                </SidebarItem>
                <SidebarItem
                  href=""
                  onClick={() =>
                    window.open("https://www.lavish.so/blog", "_blank")
                  }
                >
                  <DocumentTextIcon />
                  <SidebarLabel>Blog</SidebarLabel>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>

            <SidebarFooter>
              <Dropdown>
                <DropdownButton as={SidebarItem}>
                  <span className="flex min-w-0 items-center gap-3">
                    <Avatar
                      className="size-10 bg-zinc-900 text-white dark:bg-white dark:text-black"
                      initials={userInitials}
                      square
                      alt={current_user.name}
                    />
                    <span className="min-w-0">
                      <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                        {current_user.name}
                      </span>
                      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                        {current_user.email}
                      </span>
                    </span>
                  </span>
                  <ChevronUpIcon />
                </DropdownButton>

                <DropdownMenu className="min-w-64" anchor="top start">
                  <DropdownItem onClick={() => router.delete("/logout")}>
                    <ArrowRightStartOnRectangleIcon />
                    <DropdownLabel>Sign out</DropdownLabel>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        {children}
      </SidebarLayout>
    </Application>
  );
}

import * as Headless from "@headlessui/react";
import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Checkbox } from "../../components/checkbox";
import { Description, Field, Label } from "../../components/fieldset";
import Loader from "../../components/loader";

export default function RecipientsField({
  contacts,
  segments,
  tags,
  processing,
  recipientsCount,
  onUpdateRecipientsCount,
  onUpdateSelectedSegmentsAndTags,
}) {
  const [allSubscribersSelected, setAllSubscribersSelected] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [calculatingRecipients, setCalculatingRecipients] = useState(false);

  const debouncedCalculateRecipients = useCallback(
    debounce(async (selectedSegments, selectedTags) => {
      try {
        setCalculatingRecipients(true);

        const response = await axios.post(`/campaigns/calculate-recipients`, {
          segments: selectedSegments,
          tags: selectedTags,
        });

        onUpdateRecipientsCount(response.data.recipients_count);
      } catch (error) {
        console.error("Error calculating recipients:", error);
      } finally {
        setCalculatingRecipients(false);
      }
    }, 1000),
    []
  );

  useEffect(() => {
    return () => {
      debouncedCalculateRecipients.cancel();
    };
  }, [debouncedCalculateRecipients]);

  const handleAllSubscribersChange = () => {
    setAllSubscribersSelected(!allSubscribersSelected);

    if (!allSubscribersSelected) {
      setSelectedItems({});
    }
  };

  const toggleItem = (id) => {
    if (allSubscribersSelected) {
      setAllSubscribersSelected(false);
    }

    setSelectedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Combine segments and tags, then sort them by name
  const combinedItems = [
    ...segments.map((segment) => ({
      id: `segment-${segment.id}`,
      name: segment.name,
      count: segment.contacts_count,
    })),
    ...tags.map((tag) => ({
      id: `tag-${tag.id}`,
      name: tag.name,
      count: tag.contacts_count,
    })),
  ].sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    debouncedCalculateRecipients.cancel();

    if (!allSubscribersSelected) {
      const selectedSegments = Object.keys(selectedItems)
        .filter((key) => selectedItems[key] && key.startsWith("segment-"))
        .map((key) => key.replace("segment-", ""));
      const selectedTags = Object.keys(selectedItems)
        .filter((key) => selectedItems[key] && key.startsWith("tag-"))
        .map((key) => key.replace("tag-", ""));

      if (selectedSegments.length > 0 || selectedTags.length > 0) {
        debouncedCalculateRecipients(selectedSegments, selectedTags);
      } else {
        onUpdateRecipientsCount(0);
      }

      onUpdateSelectedSegmentsAndTags({
        segments: selectedSegments,
        tags: selectedTags,
      });
    } else {
      onUpdateRecipientsCount(contacts?.length || 0);
      onUpdateSelectedSegmentsAndTags({});
    }
  }, [
    selectedItems,
    allSubscribersSelected,
    contacts?.length,
    debouncedCalculateRecipients,
  ]);

  return (
    <Field>
      <Label>Recipients</Label>

      <div className="mt-3 flex flex-wrap gap-x-5 gap-y-2">
        {/* All Subscribers Checkbox */}
        <Headless.Field className="flex items-center gap-3">
          <Checkbox
            checked={allSubscribersSelected}
            onChange={handleAllSubscribersChange}
            disabled={processing}
          />
          <div className="flex items-center gap-1">
            <Label>All subscribers</Label>
            <Description>({contacts?.length})</Description>
          </div>
        </Headless.Field>

        {/* Combined and Sorted Segments & Tags */}
        {combinedItems.map((item) => (
          <Headless.Field key={item.id} className="flex items-center gap-3">
            <Checkbox
              checked={!!selectedItems[item.id]}
              onChange={() => toggleItem(item.id)}
              disabled={processing}
            />
            <div className="flex items-center gap-1">
              <Label>{item.name}</Label>
              <Description>({item.count})</Description>
            </div>
          </Headless.Field>
        ))}
      </div>

      <Description className="mt-3">
        {calculatingRecipients ? (
          <Loader className="size-4 text-violet-500" />
        ) : (
          `${recipientsCount} subscriber${recipientsCount !== 1 ? "s" : ""}`
        )}
      </Description>
    </Field>
  );
}

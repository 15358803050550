import * as Headless from "@headlessui/react";
import { useForm } from "@inertiajs/react";
import { useEffect, useState } from "react";
import { Button } from "../../components/button";
import { Checkbox } from "../../components/checkbox";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../components/dialog";
import DividerWithButton from "../../components/divider-with-button";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../../components/fieldset";
import { Input } from "../../components/input";
import { Listbox, ListboxOption } from "../../components/listbox";

export default function EditContactDialog({
  isOpen,
  setIsOpen,
  contact,
  fields,
  tags,
}) {
  const { data, errors, put, processing, setData, transform } = useForm({});
  const [isMoreFieldsShown, setIsMoreFieldsShown] = useState(false);

  useEffect(() => {
    setData({
      first_name: contact?.first_name || "",
      last_name: contact?.last_name || "",
      is_subscribed: contact?.is_subscribed.toString() || "false",
      ...fields.reduce((acc, field) => {
        acc[`contact_fields.${field.id}`] =
          contact?.custom_fields?.find((cf) => cf.field_id === field.id)
            ?.field_value || "";
        return acc;
      }, {}),
      ...tags.reduce((acc, tag) => {
        acc[`contact_tags.${tag.id}`] =
          contact?.tags?.some((ct) => ct.id === tag.id) || false;
        return acc;
      }, {}),
    });
  }, [contact]);

  transform((data) => ({
    contact: {
      first_name: data.first_name,
      last_name: data.last_name,
      is_subscribed: data.is_subscribed === "true",
      contact_fields_attributes: fields
        .map((field) => ({
          field_id: field.id,
          value: data[`contact_fields.${field.id}`],
        }))
        .filter((field) => field.value !== ""),
      contact_tags_attributes: tags
        .filter((tag) => data[`contact_tags.${tag.id}`])
        .map((tag) => ({ tag_id: tag.id })),
    },
  }));

  return (
    <Dialog open={isOpen} onClose={setIsOpen}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          put(`/contacts/${contact?.id}`, {
            onSuccess: () => {
              setIsOpen(false);
              setIsMoreFieldsShown(false);
            },
          });
        }}
      >
        <DialogTitle>Edit contact</DialogTitle>
        <DialogDescription>For {contact?.email}</DialogDescription>
        <DialogBody>
          <Fieldset>
            <FieldGroup>
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
                <Field>
                  <Label>First name</Label>
                  <Input
                    name="first_name"
                    placeholder="John"
                    value={data.first_name}
                    onChange={(e) => setData("first_name", e.target.value)}
                    disabled={processing}
                    invalid={!!errors.first_name}
                  />
                  {errors.first_name && (
                    <ErrorMessage>{errors.first_name}</ErrorMessage>
                  )}
                </Field>
                <Field>
                  <Label>Last name</Label>
                  <Input
                    name="last_name"
                    placeholder="Doe"
                    value={data.last_name}
                    onChange={(e) => setData("last_name", e.target.value)}
                    disabled={processing}
                    invalid={!!errors.last_name}
                  />
                  {errors.last_name && (
                    <ErrorMessage>{errors.last_name}</ErrorMessage>
                  )}
                </Field>
              </div>
              <Field>
                <Label>Is subscribed</Label>
                <Listbox
                  name="is_subscribed"
                  value={data.is_subscribed}
                  onChange={(value) => setData("is_subscribed", value)}
                  disabled={processing}
                >
                  <ListboxOption value="true">Yes</ListboxOption>
                  <ListboxOption value="false">No</ListboxOption>
                </Listbox>
              </Field>
              {tags.length > 0 && (
                <div>
                  <Label className="font-medium">Tags</Label>
                  <div className="mt-3 flex flex-wrap gap-x-5 gap-y-2">
                    {tags.map((tag) => (
                      <Headless.Field
                        key={tag.id}
                        className="flex items-center gap-3"
                      >
                        <Checkbox
                          name={tag.id}
                          checked={data[`contact_tags.${tag.id}`]}
                          onChange={(value) =>
                            setData(`contact_tags.${tag.id}`, value)
                          }
                          disabled={processing}
                        />
                        <Label>{tag.name}</Label>
                      </Headless.Field>
                    ))}
                  </div>
                </div>
              )}
              {!isMoreFieldsShown ? (
                <DividerWithButton
                  className="!mt-12 !mb-4"
                  buttonText="Show more fields"
                  onClick={() => setIsMoreFieldsShown(true)}
                />
              ) : (
                fields.map((field) => (
                  <Field key={field.id}>
                    <Label>{field.name}</Label>
                    <Input
                      name={field.id}
                      placeholder={field.name}
                      value={data[`contact_fields.${field.id}`]}
                      onChange={(e) =>
                        setData(`contact_fields.${field.id}`, e.target.value)
                      }
                      disabled={processing}
                      invalid={!!errors[`contact_fields.${field.id}`]}
                    />
                    {errors[`contact_fields.${field.id}`] && (
                      <ErrorMessage>
                        {errors[`contact_fields.${field.id}`].replace(
                          `Contact fields ${field.id}`,
                          field.name
                        )}
                      </ErrorMessage>
                    )}
                  </Field>
                ))
              )}
            </FieldGroup>
          </Fieldset>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)} disabled={processing}>
            Cancel
          </Button>
          <Button color="violet" type="submit" disabled={processing}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

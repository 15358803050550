import { formatDistanceToNow } from "date-fns";
import { Badge } from "../../components/badge";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";

export default function List({ campaigns }) {
  return (
    <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
      <TableHead>
        <TableRow>
          <TableHeader>Subject</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Last update</TableHeader>
          <TableHeader>Sent</TableHeader>
          <TableHeader>Opened</TableHeader>
          <TableHeader>Clicked</TableHeader>
          <TableHeader>Unsubscribed</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {campaigns.map((campaign) => (
          <TableRow
            key={campaign.id}
            href={`/campaigns/${campaign.id}${
              campaign.is_draft ? "/edit" : ""
            }`}
          >
            <TableCell className="font-medium">{campaign.subject}</TableCell>
            <TableCell>
              {campaign.is_draft ? (
                <Badge color="zinc">Draft</Badge>
              ) : (
                <Badge color="lime">Sent</Badge>
              )}
            </TableCell>
            <TableCell className="text-zinc-500">
              {formatDistanceToNow(campaign.updated_at, { addSuffix: true })}
            </TableCell>
            <TableCell className="text-zinc-500">
              {campaign.is_draft ? "-" : campaign.sent}
            </TableCell>
            <TableCell className="text-zinc-500">
              {campaign.is_draft ? "-" : `${campaign.open_rate}%`}
            </TableCell>
            <TableCell className="text-zinc-500">
              {campaign.is_draft ? "-" : `${campaign.click_rate}%`}
            </TableCell>
            <TableCell className="text-zinc-500">
              {campaign.is_draft ? "-" : campaign.unsubscribed}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (e) => reject(e);
    reader.readAsText(file);
  });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getEditorNodes = (editor, nodeName) => {
  const nodes = [];

  // Check if the editor exists and get the document
  if (editor && editor.state) {
    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === nodeName) {
        nodes.push({ node, pos });
      }
      return true; // Continue traversing
    });
  }

  return nodes;
};

export const deliveryRateColor = (rate) => {
  if (rate > 98) return "green";
  if (rate >= 95 && rate <= 98) return "lime";
  if (rate >= 90) return "yellow";
  return "red";
};

export const openRateColor = (rate) => {
  if (rate > 40) return "green";
  if (rate >= 30 && rate <= 40) return "lime";
  if (rate >= 20) return "yellow";
  return "red";
};

export const clickRateColor = (rate) => {
  if (rate > 5) return "green";
  if (rate >= 3 && rate <= 5) return "lime";
  if (rate >= 1) return "yellow";
  return "red";
};

export const unsubscribeRateColor = (rate) => {
  if (rate < 0.1) return "green";
  if (rate >= 0.1 && rate <= 0.5) return "lime";
  if (rate <= 1) return "yellow";
  return "red";
};

export const complaintRateColor = (rate) => {
  if (rate < 0.01) return "green";
  if (rate >= 0.01 && rate <= 0.03) return "lime";
  if (rate <= 0.05) return "yellow";
  return "red";
};

export const softBounceRateColor = (rate) => {
  if (rate < 0.5) return "green";
  if (rate >= 0.5 && rate <= 1) return "lime";
  if (rate <= 2) return "yellow";
  return "red";
};

export const hardBounceRateColor = (rate) => {
  if (rate < 0.1) return "green";
  if (rate >= 0.1 && rate <= 0.3) return "lime";
  if (rate <= 0.5) return "yellow";
  return "red";
};

export const generatePagination = (totalPages, currentPage) => {
  const range = [];
  const delta = 2; // Number of pages to show around the current page

  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 || // Always show the first page
      i === totalPages || // Always show the last page
      (i >= currentPage - delta && i <= currentPage + delta) // Pages around the current page
    ) {
      range.push(i);
    } else if (
      (i === currentPage - delta - 1 || i === currentPage + delta + 1) && // Add gaps for skipped pages
      totalPages > delta * 2 + 4
    ) {
      range.push("gap");
    }
  }
  return range;
};

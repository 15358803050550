import { Badge } from "./badge";
import { Divider } from "./divider";

export default function Stat({
  title,
  value,
  percentage,
  percentageColor,
  change,
}) {
  return (
    <div>
      <Divider />
      <div className="mt-6 text-lg/6 font-medium sm:text-sm/6">{title}</div>
      <div className="mt-3 flex gap-x-2 items-center">
        <span className="text-3xl/8 font-semibold sm:text-2xl/8">{value}</span>
        {percentage && (
          <Badge color={percentageColor || "zinc"}>{percentage}</Badge>
        )}
      </div>
      {change && (
        <div className="mt-3 text-sm/6 sm:text-xs/6">
          <Badge color={change.startsWith("+") ? "lime" : "pink"}>
            {change}
          </Badge>{" "}
          <span className="text-zinc-500">from last week</span>
        </div>
      )}
    </div>
  );
}

import { format, parseISO } from "date-fns";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Subheading } from "../../components/heading";

const CampaignsChart = ({ data }) => {
  // Format the date for tooltip
  const formatTooltipDate = (date) => {
    return format(parseISO(date), "MMM dd, yyyy");
  };

  // Custom tooltip content
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white px-4 py-2.5 shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10 rounded-xl">
          <Subheading>{formatTooltipDate(label)}</Subheading>
          <div className="mt-1 space-y-0.5">
            {payload.map((entry) => (
              <p
                key={entry.name}
                className="text-sm"
                style={{ color: entry.color }}
              >
                {entry.name}: {entry.value.toLocaleString()}
              </p>
            ))}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="h-96 w-full">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ left: 0 }}>
          <CartesianGrid strokeDasharray="3 3" className="stroke-zinc-200" />
          <XAxis
            dataKey="date"
            tickFormatter={(date) => format(parseISO(date), "MMM dd")}
            className="text-xs"
          />
          <YAxis className="text-xs" width={16} />
          <Tooltip content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{
              fontSize: "0.875rem",
              paddingTop: "0.5rem",
            }}
          />
          <Line
            type="monotone"
            dataKey="opened"
            name="Opened"
            stroke="#7c3aed"
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 6 }}
          />
          <Line
            type="monotone"
            dataKey="clicked"
            name="Clicked"
            stroke="#0891b2"
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CampaignsChart;

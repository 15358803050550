import { useForm } from "@inertiajs/react";
import Papa from "papaparse";
import { forwardRef, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { Button } from "../../components/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../../components/dialog";
import { Field, FieldGroup, Fieldset, Label } from "../../components/fieldset";
import { Input } from "../../components/input";
import { Listbox, ListboxLabel, ListboxOption } from "../../components/listbox";
import { readFileAsync } from "../../lib/utils";

export default forwardRef(function UploadCsv({ fields, props }, ref) {
  const fieldOptions = useMemo(() => {
    return [
      { value: "first_name", label: "First name" },
      { value: "last_name", label: "Last name" },
      { value: "email", label: "Email" },
      { value: "is_subscribed", label: "Is subscribed" },
      ...fields.map((field) => ({ value: field.id, label: field.name })),
      { value: "tags", label: "Tags – e.g. tag1, tag2" },
    ];
  });
  const { post, processing, recentlySuccessful, setData } = useForm({
    contacts: [],
  });
  const [isMappingOpen, setIsMappingOpen] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [mapping, setMapping] = useState({});
  const selectedFields = useMemo(() => {
    return fieldOptions.reduce((acc, field) => {
      acc[field.value] = Object.values(mapping).includes(field.value);
      return acc;
    }, {});
  }, [mapping, fieldOptions]);

  // Reset states when the dialog is closed
  useEffect(() => {
    if (!isMappingOpen) {
      setData("contacts", []);
      setCsvData({});
      setMapping({});
    }
  }, [isMappingOpen]);

  useEffect(() => {
    if (recentlySuccessful) {
      setIsMappingOpen(false);
    }
  }, [recentlySuccessful]);

  useEffect(() => {
    if (Object.values(csvData).length === 0) {
      return;
    }

    const contacts = Object.values(csvData)[0].map((_, index) => {
      const contact = {};

      // Iterate mapping and add the values to contact
      Object.keys(mapping).forEach((key) => {
        if (mapping[key]) {
          contact[mapping[key]] = csvData[key][index];
        }
      });

      return contact;
    });

    setData("contacts", contacts);
  }, [csvData, mapping]);

  async function handleChange(event) {
    const file = event.target.files?.[0];

    if (!file) return;

    try {
      const content = await readFileAsync(file);

      const result = Papa.parse(content, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
      });

      if (result.errors.length > 0) {
        toast.error("Error parsing the CSV file.");
        return;
      }

      const data = result.data;

      if (!data || data.length === 0) {
        toast.error("The CSV file is empty or invalid.");
        return;
      }

      const headers = result.meta.fields || [];

      if (headers.some((header) => !header)) {
        toast.error("The CSV file has invalid headers.");
        return;
      }

      const currentCsvData = {};

      headers.forEach((header) => {
        currentCsvData[header] = data.map((row) => row[header] ?? "");
      });

      setCsvData(currentCsvData);
      setIsMappingOpen(true);
    } catch (error) {
      toast.error("Error parsing the CSV file.");
    }

    event.target.value = "";
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (!Object.values(mapping).includes("email")) {
      toast.error("Email field is required.");
      return;
    }

    post("/contacts/import");
  }

  return (
    <>
      <input
        className="hidden"
        ref={ref}
        type="file"
        accept=".csv"
        onChange={handleChange}
        {...props}
      />

      <Dialog open={isMappingOpen} onClose={setIsMappingOpen}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>
            Map your CSV data to our contact fields or tags
          </DialogTitle>
          <DialogDescription>
            Email is required to identify contacts. You can map other fields and
            also tag them using a column with comma-separated names.
          </DialogDescription>
          <DialogBody>
            <Fieldset>
              <FieldGroup>
                {Object.keys(csvData).map((column, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4"
                  >
                    <Field disabled>
                      <Label>{column}</Label>
                      <Input value={csvData[column][0]} />
                    </Field>
                    <Field disabled={processing}>
                      <Label className="invisible">Field</Label>
                      <Listbox
                        name="field"
                        value={mapping[column] ? mapping[column] : ""}
                        onChange={(value) =>
                          setMapping((prev) => ({
                            ...prev,
                            [column]: value,
                          }))
                        }
                      >
                        <ListboxOption value="">
                          <ListboxLabel>Select a field</ListboxLabel>
                        </ListboxOption>
                        {fieldOptions.map((option) => (
                          <ListboxOption
                            key={option.value}
                            value={option.value}
                            disabled={
                              selectedFields[option.value] &&
                              mapping[column] !== option.value
                            }
                          >
                            <ListboxLabel>{option.label}</ListboxLabel>
                          </ListboxOption>
                        ))}
                      </Listbox>
                    </Field>
                  </div>
                ))}
              </FieldGroup>
            </Fieldset>
          </DialogBody>
          <DialogActions>
            <Button
              plain
              onClick={() => setIsMappingOpen(false)}
              disabled={processing}
            >
              Cancel
            </Button>
            <Button color="violet" type="submit" disabled={processing}>
              Import contacts
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
});

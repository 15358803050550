import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head } from "@inertiajs/react";
import LinkExtension from "@tiptap/extension-link";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Badge } from "../../components/badge";
import Editor from "../../components/editor";
import { Subheading } from "../../components/heading";
import { Link } from "../../components/link";
import { Select } from "../../components/select";
import Stat from "../../components/stat";
import {
  clickRateColor,
  cn,
  complaintRateColor,
  deliveryRateColor,
  hardBounceRateColor,
  openRateColor,
  softBounceRateColor,
  unsubscribeRateColor,
} from "../../lib/utils";
import {
  DraggableDivider,
  DraggableImage,
} from "../../tiptap/extensions/draggable";
import { DynamicField } from "../../tiptap/extensions/dynamic-field";

export default function Show({ campaign, metrics }) {
  const editor = useEditor({
    editable: false,
    content: JSON.parse(campaign.json_content ?? ""),
    editorProps: {
      attributes: {
        class: cn(
          "prose prose-base prose-zinc mx-auto max-w-[708px] caret-zinc-900 focus:outline-none"
        ),
      },
    },
    extensions: [
      StarterKit.configure({ horizontalRule: false }),
      Typography,
      LinkExtension.extend({ inclusive: false }).configure({
        HTMLAttributes: {
          class: "hover:cursor-pointer",
        },
      }),
      DraggableDivider,
      DraggableImage,
      Underline,
      DynamicField,
    ],
  });

  return (
    <>
      <Head title={campaign.subject} />

      <div className="flex justify-between max-lg:items-end items-center">
        <div className="max-lg:hidden">
          <Link
            href="/campaigns"
            className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400"
          >
            <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
            Campaigns
          </Link>
        </div>
        <div className="flex items-center gap-x-2">
          <Subheading level={1}>{campaign.subject}</Subheading>
          <Badge color="lime">Sent</Badge>
        </div>
        <div />
      </div>

      <div className="mt-8 flex items-end justify-between">
        <Subheading>Metrics</Subheading>
        <div>
          <Select name="period" className="invisible">
            <option value="last_week">Last week</option>
            <option value="last_two">Last two weeks</option>
            <option value="last_month">Last month</option>
            <option value="last_quarter">Last quarter</option>
          </Select>
        </div>
      </div>

      <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <Stat title="Sent" value={metrics.sent_count} />
        <Stat
          title="Delivered"
          value={metrics.delivered_count}
          percentage={`${metrics.delivery_rate}% of sent emails`}
          percentageColor={deliveryRateColor(metrics.delivery_rate)}
        />
        <Stat
          title="Opened"
          value={metrics.open_count}
          percentage={`${metrics.open_rate}% of delivered emails`}
          percentageColor={openRateColor(metrics.open_rate)}
        />
        <Stat
          title="Clicked"
          value={metrics.click_count}
          percentage={`${metrics.click_rate}% of delivered emails`}
          percentageColor={clickRateColor(metrics.click_rate)}
        />
      </div>

      <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
        <Stat
          title="Unsubscribed"
          value={metrics.unsubscribe_count}
          percentage={`${metrics.unsubscribe_rate}% of opened emails`}
          percentageColor={unsubscribeRateColor(metrics.unsubscribe_rate)}
        />
        <Stat
          title="Complained"
          value={metrics.complaint_count}
          percentage={`${metrics.complaint_rate}% of opened emails`}
          percentageColor={complaintRateColor(metrics.complaint_rate)}
        />
        <Stat
          title="Soft-bounced"
          value={metrics.soft_bounce_count}
          percentage={`${metrics.soft_bounce_rate}% of sent emails`}
          percentageColor={softBounceRateColor(metrics.soft_bounce_rate)}
        />
        <Stat
          title="Hard-bounced"
          value={metrics.hard_bounce_count}
          percentage={`${metrics.hard_bounce_rate}% of sent emails`}
          percentageColor={hardBounceRateColor(metrics.hard_bounce_rate)}
        />
      </div>

      <Subheading className="mt-14">Content</Subheading>

      <div className="mt-4 rounded-xl ring-1 ring-zinc-950/10 dark:ring-white/10 forced-colors:outline">
        <div className="px-4 py-20">
          <Editor editor={editor} />
        </div>
      </div>
    </>
  );
}

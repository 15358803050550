import { usePage } from "@inertiajs/react";
import confetti from "canvas-confetti";
import { useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";

export default function Application({ children }) {
  const { flash } = usePage().props;

  useEffect(() => {
    if (flash.notice) {
      toast.success(flash.notice, { duration: 3000 });
    } else if (flash.alert) {
      toast.error(flash.alert);
    } else if (flash.confetti) {
      confetti({
        particleCount: 500,
        spread: 360,
        origin: { y: 0.4 },
        gravity: 0.8,
        ticks: 200,
        colors: [
          "#ff4d4f",
          "#ff7a45",
          "#ffe58f",
          "#73d13d",
          "#40a9ff",
          "#9254de",
        ], // Vibrant Lavish-themed colors
        scalar: 1.2, // Slightly larger particles
      });
      toast.success(flash.confetti, { duration: 4000 });
    }
  }, [flash]);

  return (
    <>
      <Toaster toastOptions={{ className: "text-sm font-medium !max-w-md" }} />
      {children}
    </>
  );
}

import { ChevronLeftIcon } from "@heroicons/react/16/solid";
import { Head } from "@inertiajs/react";
import axios from "axios";
import { useState } from "react";
import { Button } from "../../components/button";
import { Heading, Subheading } from "../../components/heading";
import { Link } from "../../components/link";
import { cn } from "../../lib/utils";
import ContactList from "./contact-list";
import FilterGroups from "./filter-groups";

export default function Show({
  segment,
  filters,
  contacts: initialContacts,
  fields,
  contacts_copy: initialContactsCopy,
  existing_filters,
  pagination: initialPagination,
}) {
  const [showFilterGroups, setShowFilterGroups] = useState(
    existing_filters.length > 0
  );
  const [contacts, setContacts] = useState(initialContacts);
  const [contactsCopy, setContactsCopy] = useState(initialContactsCopy);
  const [pagination, setPagination] = useState(initialPagination);
  const [currentFilters, setCurrentFilters] = useState(existing_filters);

  const fetchFilteredContacts = async (filters, page = null) => {
    try {
      const { data } = await axios.post(
        `/segments/${segment.id}/filter-contacts`,
        {
          filters,
          page,
        }
      );

      setContacts(data.contacts);
      setContactsCopy(data.contacts_copy);
      setPagination(data.pagination);

      if (page) {
        const newUrl = `${window.location.pathname}${
          page > 1 ? `?page=${page}` : ""
        }`;
        window.history.pushState({}, "", newUrl);
      }
    } catch (error) {
      console.error("Error fetching filtered contacts:", error);
    }
  };

  const handleFiltersChange = (newFilters) => {
    setCurrentFilters(newFilters);
    fetchFilteredContacts(newFilters);
  };

  const handlePageChange = (newPage) => {
    fetchFilteredContacts(currentFilters, newPage);
  };

  return (
    <>
      <Head title={segment.name} />

      <div className="max-lg:hidden">
        <Link
          href="/segments"
          className="inline-flex items-center gap-2 text-sm/6 text-zinc-500 dark:text-zinc-400"
        >
          <ChevronLeftIcon className="size-4 fill-zinc-400 dark:fill-zinc-500" />
          <span className="max-w-48 line-clamp-1">Segments</span>
        </Link>
      </div>

      <div className="mt-4 lg:mt-8">
        <div className="flex flex-wrap justify-between items-end gap-x-6 gap-y-4">
          <div className="flex items-center gap-4">
            <Heading>{segment.name}</Heading>
          </div>
          <div className="flex gap-4">
            <Button
              color="violet"
              className={cn(showFilterGroups && "invisible")}
              onClick={() => setShowFilterGroups(true)}
            >
              Add filter
            </Button>
          </div>
        </div>
      </div>

      {showFilterGroups && (
        <div className="mt-12">
          <FilterGroups
            existingFilters={currentFilters}
            filters={filters}
            segment={segment}
            onFiltersChange={handleFiltersChange}
          />
        </div>
      )}

      <div className={cn(showFilterGroups ? "mt-14" : "mt-12")}>
        <Subheading>{contactsCopy}</Subheading>
        <div className="-mt-4">
          <ContactList
            contacts={contacts}
            fields={fields}
            pagination={pagination}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

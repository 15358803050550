import { Head } from "@inertiajs/react";
import { Button } from "../../components/button";
import { Heading } from "../../components/heading";
import Empty from "./empty";
import List from "./list";

export default function Index({ campaigns }) {
  return (
    <>
      <Head title="Campaigns" />

      <div className="flex items-end justify-between gap-4">
        <Heading>Campaigns</Heading>
        {campaigns.length > 0 && (
          <div className="flex gap-4">
            <Button color="violet" href="/campaigns/new">
              Create campaign
            </Button>
          </div>
        )}
      </div>

      {campaigns.length > 0 ? <List campaigns={campaigns} /> : <Empty />}
    </>
  );
}

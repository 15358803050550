import {
  FolderPlusIcon,
  IdentificationIcon,
  PlusIcon,
  TagIcon,
  TrashIcon,
} from "@heroicons/react/16/solid";
import { router, useForm } from "@inertiajs/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Badge } from "../../components/badge";
import { Button } from "../../components/button";
import { Divider } from "../../components/divider";
import { Subheading } from "../../components/heading";
import { Input } from "../../components/input";
import {
  Listbox,
  ListboxBadge,
  ListboxLabel,
  ListboxOption,
} from "../../components/listbox";

const standardFilterOperators = [
  { name: "Contains", value: "contains" },
  { name: "Does not contain", value: "does_not_contain" },
  { name: "Equals", value: "equals" },
  { name: "Does not equal", value: "does_not_equal" },
];

const tagFilterOperators = [
  { name: "Is", value: "is" },
  { name: "Is not", value: "is_not" },
];

const initialFilter = {
  fieldIndex: 0,
  operator: standardFilterOperators[0].value,
  value: "",
};

const initialGroup = {
  conjunction: "and",
  filters: [{ ...initialFilter }],
};

const FilterGroups = ({
  segment,
  filters: availableFilters,
  existingFilters,
  onFiltersChange,
}) => {
  // Separate tag filters from other filters
  const { tagFilters, standardFilters } = useMemo(() => {
    return availableFilters.reduce(
      (acc, filter) => {
        if (filter.filter_type === "tag") {
          acc.tagFilters.push(filter);
        } else {
          acc.standardFilters.push(filter);
        }
        return acc;
      },
      { tagFilters: [], standardFilters: [] }
    );
  }, [availableFilters]);

  // Add special "Tag" option if tags exist
  const filterOptions = useMemo(() => {
    const options = standardFilters.map((filter, index) => ({
      type: "standard",
      index,
      filter,
    }));

    if (tagFilters.length > 0) {
      options.push({
        type: "tag",
        index: standardFilters.length,
        filter: { filter_type: "tag", field_key: "tags", label: "Tag" },
      });
    }

    return options;
  }, [standardFilters, tagFilters]);

  // Convert existing filters to groups format
  const initialGroups = useMemo(() => {
    if (!existingFilters?.length) return [{ ...initialGroup }];

    const groupedFilters = existingFilters.reduce((acc, filter) => {
      const groupIndex = filter.group_number - 1;
      if (!acc[groupIndex]) {
        acc[groupIndex] = {
          conjunction: filter.combinator,
          filters: [],
        };
      }

      let fieldIndex;
      if (filter.filter_type === "tag") {
        fieldIndex = filterOptions.findIndex((option) => option.type === "tag");
      } else {
        fieldIndex = filterOptions.findIndex(
          (option) =>
            option.filter.filter_type === filter.filter_type &&
            option.filter.field_key === filter.field_key
        );
      }

      acc[groupIndex].filters.push({
        fieldIndex,
        operator: filter.operator,
        value: filter.value,
      });

      return acc;
    }, []);

    return groupedFilters.length ? groupedFilters : [{ ...initialGroup }];
  }, [existingFilters, filterOptions]);

  const [groups, setGroups] = useState(initialGroups);
  const timeoutRef = useRef(null);
  const { post, processing, setData } = useForm({
    filters: [],
  });

  // Add this to track if groups were changed by user input
  const userChangedRef = useRef(false);

  // Function to format filters for submission or preview
  const formatFilters = useMemo(() => {
    return groups.flatMap((group, groupIndex) =>
      group.filters.map((filter) => {
        const selectedOption = filterOptions[filter.fieldIndex];

        if (selectedOption.type === "tag") {
          return {
            filter_type: "tag",
            field_key: "tags",
            value: filter.value,
            operator: filter.operator,
            group_number: groupIndex + 1,
            combinator: group.conjunction,
          };
        }

        const selectedFilter = selectedOption.filter;
        return {
          filter_type: selectedFilter.filter_type,
          field_key: selectedFilter.field_key,
          value: filter.value,
          operator: filter.operator,
          group_number: groupIndex + 1,
          combinator: group.conjunction,
        };
      })
    );
  }, [groups, filterOptions]);

  // Debounced update whenever groups change
  useEffect(() => {
    if (!userChangedRef.current) return;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      onFiltersChange(formatFilters);
      userChangedRef.current = false;
    }, 1500);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [groups, onFiltersChange]);

  useEffect(() => {
    setData("filters", formatFilters);
  }, [formatFilters]);

  const updateFilter = (groupIndex, filterIndex, field, value) => {
    userChangedRef.current = true;

    setGroups((currentGroups) => {
      const newGroups = [...currentGroups];
      newGroups[groupIndex] = {
        ...newGroups[groupIndex],
        filters: [
          ...newGroups[groupIndex].filters.slice(0, filterIndex),
          {
            ...newGroups[groupIndex].filters[filterIndex],
            [field]: value,
          },
          ...newGroups[groupIndex].filters.slice(filterIndex + 1),
        ],
      };
      return newGroups;
    });
  };

  const addFilter = (groupIndex) => {
    userChangedRef.current = true;

    setGroups((currentGroups) => {
      const newGroups = [...currentGroups];
      newGroups[groupIndex] = {
        ...newGroups[groupIndex],
        filters: [...newGroups[groupIndex].filters, { ...initialFilter }],
      };
      return newGroups;
    });
  };

  const addGroup = () => {
    userChangedRef.current = true;
    setGroups((currentGroups) => [...currentGroups, { ...initialGroup }]);
  };

  const updateConjunction = (groupIndex, value) => {
    userChangedRef.current = true;

    setGroups((currentGroups) => {
      const newGroups = [...currentGroups];
      newGroups[groupIndex] = {
        ...newGroups[groupIndex],
        conjunction: value,
      };
      return newGroups;
    });
  };

  const renderFilterOperatorInput = (filter, groupIndex, filterIndex) => {
    const selectedOption = filterOptions[filter.fieldIndex];
    const isTagFilter = selectedOption.type === "tag";

    // Hide operator for is_subscribed field
    if (selectedOption.filter.field_key === "is_subscribed") {
      // Ensure the operator is always "equals" for is_subscribed
      if (filter.operator !== "equals") {
        updateFilter(groupIndex, filterIndex, "operator", "equals");
      }
      return null;
    }

    return (
      <Listbox
        className="col-span-1"
        value={filter.operator}
        onChange={(value) =>
          updateFilter(groupIndex, filterIndex, "operator", value)
        }
        disabled={processing}
      >
        {(isTagFilter ? tagFilterOperators : standardFilterOperators).map(
          (operator) => (
            <ListboxOption key={operator.value} value={operator.value}>
              {operator.name}
            </ListboxOption>
          )
        )}
      </Listbox>
    );
  };

  const renderFilterValueInput = (filter, groupIndex, filterIndex) => {
    const selectedOption = filterOptions[filter.fieldIndex];
    const isSubscribedField =
      selectedOption.filter.field_key === "is_subscribed";
    const columnSpanClass = isSubscribedField ? "col-span-3" : "col-span-2";

    if (selectedOption.type === "tag") {
      return (
        <Listbox
          className={columnSpanClass}
          value={filter.value || tagFilters[0]?.field_key}
          onChange={(value) =>
            updateFilter(groupIndex, filterIndex, "value", value)
          }
          disabled={processing}
        >
          {tagFilters.map((tag) => (
            <ListboxOption key={tag.field_key} value={tag.field_key}>
              <TagIcon />
              <ListboxLabel>{tag.label}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      );
    }

    if (isSubscribedField) {
      return (
        <Listbox
          className={columnSpanClass}
          value={filter.value || "Yes"}
          onChange={(value) =>
            updateFilter(groupIndex, filterIndex, "value", value)
          }
          disabled={processing}
        >
          <ListboxOption value="Yes">Yes</ListboxOption>
          <ListboxOption value="No">No</ListboxOption>
        </Listbox>
      );
    }

    return (
      <Input
        className={columnSpanClass}
        placeholder="Value"
        value={filter.value}
        onChange={(e) =>
          updateFilter(groupIndex, filterIndex, "value", e.target.value)
        }
        disabled={processing}
      />
    );
  };

  return (
    <>
      <div className="flex justify-between items-end">
        <Subheading>Filters</Subheading>
        <div className="flex gap-4">
          <Button
            outline
            disabled={processing}
            onClick={() => {
              router.visit(`/segments/${segment.id}`, { preserveScroll: true });
            }}
          >
            Reset
          </Button>
          <Button
            color="violet"
            disabled={processing}
            onClick={() => {
              post(`/segments/${segment.id}/save-filters`, {
                preserveScroll: true,
              });
            }}
          >
            Save
          </Button>
        </div>
      </div>

      <div className="mt-6 space-y-8">
        {groups.map((group, groupIndex) => (
          <React.Fragment key={groupIndex}>
            {groupIndex > 0 && (
              <div className="relative">
                <Divider />
                <span className="absolute bg-white dark:bg-zinc-900 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 px-2">
                  <Badge color="orange">AND</Badge>
                </span>
              </div>
            )}

            <div className="flex flex-col gap-6">
              <ListboxBadge
                value={group.conjunction}
                color={group.conjunction === "and" ? "yellow" : "lime"}
                onChange={(value) => updateConjunction(groupIndex, value)}
                disabled={processing}
              >
                <ListboxOption value="and">
                  All of these must be true
                </ListboxOption>
                <ListboxOption value="or">
                  Any of these must be true
                </ListboxOption>
              </ListboxBadge>

              {group.filters.map((filter, filterIndex) => {
                const selectedOption = filterOptions[filter.fieldIndex];
                const isTagFilter = selectedOption.type === "tag";

                return (
                  <div key={filterIndex} className="flex gap-4">
                    <div className="flex-1 grid grid-cols-4 gap-4">
                      <Listbox
                        className="col-span-1"
                        value={filter.fieldIndex}
                        onChange={(value) => {
                          const newOption = filterOptions[value];
                          updateFilter(
                            groupIndex,
                            filterIndex,
                            "fieldIndex",
                            value
                          );

                          // Reset operator and value when switching between different filter types
                          if (isTagFilter !== (newOption.type === "tag")) {
                            updateFilter(
                              groupIndex,
                              filterIndex,
                              "operator",
                              newOption.type === "tag"
                                ? tagFilterOperators[0].value
                                : standardFilterOperators[0].value
                            );
                            updateFilter(
                              groupIndex,
                              filterIndex,
                              "value",
                              newOption.type === "tag"
                                ? tagFilters[0]?.field_key
                                : ""
                            );
                          } else if (
                            newOption.filter.field_key === "is_subscribed"
                          ) {
                            // Set default value and operator for is_subscribed field
                            updateFilter(
                              groupIndex,
                              filterIndex,
                              "value",
                              "Yes"
                            );
                            updateFilter(
                              groupIndex,
                              filterIndex,
                              "operator",
                              "equals"
                            );
                          }
                        }}
                        disabled={processing}
                      >
                        {filterOptions.map((option, index) => (
                          <ListboxOption key={index} value={index}>
                            {option.type === "tag" ? (
                              <TagIcon />
                            ) : option.filter.filter_type ===
                                "built_in_field" ||
                              option.filter.filter_type === "custom_field" ? (
                              <IdentificationIcon />
                            ) : null}
                            <ListboxLabel>{option.filter.label}</ListboxLabel>
                          </ListboxOption>
                        ))}
                      </Listbox>

                      {renderFilterOperatorInput(
                        filter,
                        groupIndex,
                        filterIndex
                      )}
                      {renderFilterValueInput(filter, groupIndex, filterIndex)}
                    </div>
                    <Button
                      plain
                      disabled={
                        processing ||
                        (groups.length === 1 && group.filters.length === 1)
                      }
                      onClick={() => {
                        setGroups((currentGroups) => {
                          const newGroups = [...currentGroups];
                          newGroups[groupIndex] = {
                            ...newGroups[groupIndex],
                            filters: newGroups[groupIndex].filters.filter(
                              (_, i) => i !== filterIndex
                            ),
                          };
                          if (newGroups[groupIndex].filters.length === 0) {
                            return newGroups.filter((_, i) => i !== groupIndex);
                          }
                          return newGroups;
                        });
                      }}
                    >
                      <TrashIcon className="size-5" />
                    </Button>
                  </div>
                );
              })}

              <div className="flex gap-4">
                <Button
                  outline
                  onClick={() => addFilter(groupIndex)}
                  disabled={processing}
                >
                  <PlusIcon />
                  Add filter
                </Button>
                {groupIndex === groups.length - 1 && (
                  <Button outline onClick={addGroup} disabled={processing}>
                    <FolderPlusIcon />
                    Add filter group
                  </Button>
                )}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default FilterGroups;

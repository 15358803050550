import { EllipsisHorizontalIcon } from "@heroicons/react/16/solid";
import { useMemo } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../../components/dropdown";
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../../components/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { generatePagination } from "../../lib/utils";

export default function List({ contacts, fields, pagination, onEdit }) {
  const pages = useMemo(
    () => generatePagination(pagination.total_pages, pagination.current_page),
    [pagination.total_pages, pagination.current_page]
  );

  return (
    <>
      <Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
        <TableHead>
          <TableRow>
            <TableHeader>Email</TableHeader>
            <TableHeader>First name</TableHeader>
            <TableHeader>Last name</TableHeader>
            <TableHeader>Is subscribed</TableHeader>
            {fields.map((field) => (
              <TableHeader key={field.name}>{field.name}</TableHeader>
            ))}
            <TableHeader>Tags</TableHeader>
            <TableHeader className="relative w-0">
              <span className="sr-only">Actions</span>
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell className="font-medium">{contact.email}</TableCell>
              <TableCell className="text-zinc-500">
                {contact.first_name || "-"}
              </TableCell>
              <TableCell className="text-zinc-500">
                {contact.last_name || "-"}
              </TableCell>
              <TableCell className="text-zinc-500">
                {contact.is_subscribed ? "Yes" : "No"}
              </TableCell>
              {contact.custom_fields.map((field) => (
                <TableCell className="text-zinc-500" key={field.field_name}>
                  {field.field_value || "-"}
                </TableCell>
              ))}
              <TableCell className="text-zinc-500">
                {contact.tags.length > 0 ? (
                  <div title={contact.tags.map((tag) => tag.name).join(", ")}>
                    {contact.tags.length === 1
                      ? contact.tags[0].name
                      : `${contact.tags[0].name} and ${
                          contact.tags.length - 1
                        } more`}
                  </div>
                ) : (
                  "-"
                )}
              </TableCell>
              <TableCell>
                <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  <Dropdown>
                    <DropdownButton plain aria-label="More options">
                      <EllipsisHorizontalIcon />
                    </DropdownButton>
                    <DropdownMenu anchor="bottom end">
                      <DropdownItem onClick={() => onEdit(contact)}>
                        <DropdownLabel>Edit</DropdownLabel>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {pagination.total_pages > 1 && (
        <Pagination aria-label="Page navigation" className="mt-6">
          <PaginationPrevious
            href={pagination.prev_page ? `?page=${pagination.prev_page}` : null}
          >
            Previous
          </PaginationPrevious>
          <PaginationList>
            {pages.map((page, index) =>
              page === "gap" ? (
                <PaginationGap key={`gap-${index}`} />
              ) : (
                <PaginationPage
                  key={page}
                  href={`?page=${page}`}
                  current={page === pagination.current_page}
                >
                  {page}
                </PaginationPage>
              )
            )}
          </PaginationList>
          <PaginationNext
            href={pagination.next_page ? `?page=${pagination.next_page}` : null}
          >
            Next
          </PaginationNext>
        </Pagination>
      )}
    </>
  );
}

import { useMemo } from "react";
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from "../../components/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/table";
import { generatePagination } from "../../lib/utils";

export default function List({ contacts, fields, pagination, onPageChange }) {
  const pages = useMemo(
    () => generatePagination(pagination.total_pages, pagination.current_page),
    [pagination.total_pages, pagination.current_page]
  );

  const handlePageClick = (e, page) => {
    e.preventDefault();
    onPageChange(page);
  };

  return (
    <>
      <Table className="mt-8 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">
        <TableHead>
          <TableRow>
            <TableHeader>Email</TableHeader>
            <TableHeader>First name</TableHeader>
            <TableHeader>Last name</TableHeader>
            <TableHeader>Is subscribed</TableHeader>
            {fields.map((field) => (
              <TableHeader key={field.name}>{field.name}</TableHeader>
            ))}
            <TableHeader>Tags</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell className="font-medium">{contact.email}</TableCell>
              <TableCell className="text-zinc-500">
                {contact.first_name || "-"}
              </TableCell>
              <TableCell className="text-zinc-500">
                {contact.last_name || "-"}
              </TableCell>
              <TableCell className="text-zinc-500">
                {contact.is_subscribed ? "Yes" : "No"}
              </TableCell>
              {contact.custom_fields.map((field) => (
                <TableCell className="text-zinc-500" key={field.field_name}>
                  {field.field_value || "-"}
                </TableCell>
              ))}
              <TableCell className="text-zinc-500">
                {contact.tags.length > 0 ? (
                  <div title={contact.tags.map((tag) => tag.name).join(", ")}>
                    {contact.tags.length === 1
                      ? contact.tags[0].name
                      : `${contact.tags[0].name} and ${
                          contact.tags.length - 1
                        } more`}
                  </div>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {pagination.total_pages > 1 && (
        <Pagination aria-label="Page navigation" className="mt-6">
          <PaginationPrevious
            onClick={
              pagination.prev_page &&
              ((e) => handlePageClick(e, pagination.prev_page))
            }
          >
            Previous
          </PaginationPrevious>
          <PaginationList>
            {pages.map((page, index) =>
              page === "gap" ? (
                <PaginationGap key={`gap-${index}`} />
              ) : (
                <PaginationPage
                  key={page}
                  onClick={(e) => handlePageClick(e, page)}
                  current={page === pagination.current_page}
                >
                  {page}
                </PaginationPage>
              )
            )}
          </PaginationList>
          <PaginationNext
            onClick={
              pagination.next_page &&
              ((e) => handlePageClick(e, pagination.next_page))
            }
          >
            Next
          </PaginationNext>
        </Pagination>
      )}
    </>
  );
}
